import * as React from 'react';

import SEO from 'components/shared/SEO';

import { PrivacyPolicy } from 'components/PrivacyPolicy/PrivacyPolicy';
import Footer from 'components/Footer/Footer';

const CookiesPolicyPage = ({ pageContext }) => {
  return (
    <>
      <SEO
        title="Polityka cookies - Kameralny Prokocim"
        ogTitle="Polityka cookies - Kameralny Prokocim"
      />
      <PrivacyPolicy
        heading={pageContext.heading}
        content={pageContext.content}
        isCookiesPolicy
      />
      <Footer />
    </>
  );
};

export default CookiesPolicyPage;
